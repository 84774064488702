import * as React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import Seo from "../components/Seo";

const NotFoundPage = () => {
	return (
		<>
			<Seo title={"404"} />
			<Global
				styles={css`
					main {
						min-height: calc(100vh - var(--header-height));
						display: flex;
						flex-direction: column;
					}
					footer {
						margin-top: auto;
					}
				`}
			/>
			<Wrap>
				<h1>Page not found</h1>
				<p>
					Sorry, we couldn’t find the page you were looking for.
					<br />
					<br />
					<Link to="/">Back to home</Link>
				</p>
			</Wrap>
		</>
	);
};

const Wrap = styled.div`
	padding: var(--padding);
`;

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
